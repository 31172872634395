<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-event-types-list')"
        @save="createItem"
        :title="currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(createItem)" @reset.prevent="resetForm">
        <b-card>
          <!-- Status -->
          <div class="mb-2">
            <b-form-checkbox
                v-model="currentItem.status"
                value="1"
                name="status"
                class="custom-control-success"
            >
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>

          <!-- Name -->
          <validation-provider #default="validationContext" :name="$t('Event Type')" rules="required">
            <b-form-group :label="$t('Event Type')">
              <b-form-input id="booking_type" v-model="currentItem.name" trim :placeholder="$t('Event Type')"
              />

              <small class="text-danger">{{ validationContext.errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger"
              >{{ $t(validation.name[0]) }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col cols="6">
              <b-form-group :label="$t('Booking type')">
                <b-form-select
                    v-model="currentItem.booking_type"
                    :options="eventTypeForm"
                    :placeholder="$t('Please select an Booking Type')"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group v-if="eventTypeForm == 2" :label="$t('Booking Time')">
                <b-form-input
                    v-model="currentItem.event_type_booking_time"
                    id="event_type_booking_time"
                    :placeholder="$t('Insert your Booking Time')"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

          </b-row>

          <div class="mb-2 mt-1">
            <b-form-checkbox
                v-model="currentItem.is_multidayevent"
                value="1"
                name="multidayevent"
                class="custom-control-success"
            >
              {{ $t('Multiday event') }}
            </b-form-checkbox>
          </div>

          <b-form-checkbox
              v-model="currentItem.editable"
              value="1"
              name="status"
              class="custom-control-success"
          >
            {{ $t('Editable') }}
          </b-form-checkbox>

          <b-form-group class="mt-1" :label='$t("payment type")' label-for="email">
            <b-form-select v-model="currentItem.payment_type" :options="paymentTypeOptions"></b-form-select>
          </b-form-group>
        </b-card>
        <b-card>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
            {{ $t('Add') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { required, alphaNum, email } from '@validations'
import moment from 'moment'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      currentItem: {
        id: '',
        name: '',
        credits: '',
        booking_time: '',
        booking_type: 1,
        event_type_booking_time: null,
        payment_type: 0,
        event_type_form: 1,
        editable: '',
        status: 1,
        is_multidayevent: 0,
      },
      paymentTypeOptions: [
        {
          value: 0,
          text: this.$i18n.t('one time payable event')
        },
        {
          value: 1,
          text: this.$i18n.t('payment per participant')
        },
      ],
      eventTypeForm: [
        {
          value: 1,
          text: this.$t('Fixed Appointment')
        },
        {
          value: 2,
          text: this.$t('Dynamic appointment - chosen by the Customer')
        }
      ],
      loaded: true,
      validation: {},
      validate: false,
    }
  },

  methods: {
    createItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperAddItem(`/eventtypes`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
                this.$router.push(
                    {
                      name: 'apps-event-types-edit',
                      params: {
                        id: response.data.id
                      },
                    },
                    () => setTimeout(() => {
                      this.hToast('success', 'Success', 'Item saved')
                    })
                )
              })
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  mounted() {
  },
}
</script>

<style>
</style>
